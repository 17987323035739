import './SingleCellQuote.scss';
import React from 'react';
import Typography from '@components/Typography';
import Container from '@components/Container';
import QuoteStartIcon from './assets/quote-start.svg';
import QuoteEndIcon from './assets/quote-end.svg';
import czi from './assets/czi.png';

const SingleCellQuote: React.FC = () => {
  return (
    <Container className="TDB-SingleCellQuoteWrapper">
      <div className="TDB-SingleCellQuote">
        <div className="TDB-SingleCellQuote__logo">
          <img src={czi} alt="czi logo" />
        </div>
        <div className="TDB-SingleCellQuote__text">
          <Typography as="p" className="TDB-SingleCellQuote__text__quote" color="neutral-800" fontSize="subheading-2" fontWeight="medium">
            <img src={QuoteStartIcon} alt="quote" />
            TileDB's performant, scalable and cloud-native solution is ideal for our efforts at CZI to assemble and distribute one of the
            largest repositories of single-cell biological data in the world
            <img src={QuoteEndIcon} alt="quote" />
          </Typography>
          <Typography fontSize="subheading-2" fontWeight="semi-bold" color="brand-900" className="TDB-SingleCellQuote__text__author" as="p">
            Ambrose Carr
          </Typography>
          <Typography fontSize="body" fontWeight="semi-bold" color="neutral-800" className="TDB-SingleCellQuote__text__author-title" as="p">
            Group Product Lead, Chan Zuckerberg Initiative
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default SingleCellQuote;
