import './SingleCellOpenSource.scss';
import React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import InlineButton from '@components/InlineButton';
import links from '@constants/links';
import cziLogo from './assets/czi.svg';
import plusIcon from './assets/plus.svg';
import tileDBMonogram from './assets/tiledb-monogram.svg';
import iconGithub from './assets/github.inline.svg';

const SingleCellOpenSource: React.FC = () => {
  return (
    <Container>
      <div className="SingleCellOpenSource">
        <div className="SingleCellOpenSource__card-label-wrapper">
          <div className="SingleCellOpenSource__card-label-curve" />
          <div className="SingleCellOpenSource__card-label">
            <Typography
              className="SingleCellOpenSource__title"
              as="h3"
              color="neutral-900"
              fontWeight="bold"
              fontSize="heading-1"
              align="center"
            >
              Open source code
            </Typography>
            <div className="SingleCellOpenSource__icons">
              <img src={cziLogo} alt="CZI logo" className="SingleCellOpenSource__icons__czi" />
              <img src={plusIcon} alt="plus" className="SingleCellOpenSource__icons__plus" />
              <img src={tileDBMonogram} alt="tiledb logo" className="SingleCellOpenSource__icons__tiledb" />
            </div>
            <Typography
              className="SingleCellOpenSource__description"
              fontSize="subheading-3"
              color="neutral-600"
              fontWeight="medium"
              align="center"
              as="p"
            >
              We partnered with CZI to deliver the spec of an open source data model and corresponding implementations in R and Python for
              single-cell biology.
            </Typography>
          </div>
          <div className="SingleCellOpenSource__card-label-curve" />
        </div>
        <div className="SingleCellOpenSource__card">
          <div className="SingleCellOpenSource__card__item">
            <div className="SingleCellOpenSource__card__item__text">
              <Typography
                as="h4"
                color="brand-900"
                fontWeight="bold"
                fontSize="heading-4"
                className="SingleCellOpenSource__card__item__title"
              >
                SOMA SPEC
              </Typography>
              <Typography
                as="p"
                className="SingleCellOpenSource__card__item__description"
                align="center"
                color="neutral-600"
                fontSize="subheading-2"
                fontWeight="medium"
              >
                SOMA is a flexible, extensible, and open-source data model for representing annotated matrices, commonly used in single cell
                biology.
              </Typography>
            </div>
            <InlineButton variant="light" icon={iconGithub} target="_blank" href="https://github.com/single-cell-data/SOMA">
              Spec
            </InlineButton>
          </div>
          <div className="SingleCellOpenSource__card__item">
            <div className="SingleCellOpenSource__card__item__text">
              <Typography
                as="h4"
                color="brand-900"
                fontWeight="bold"
                fontSize="heading-4"
                className="SingleCellOpenSource__card__item__title"
              >
                TILEDB SOMA
              </Typography>
              <Typography
                as="p"
                className="SingleCellOpenSource__card__item__description"
                align="center"
                color="neutral-600"
                fontSize="subheading-2"
                fontWeight="medium"
              >
                TileDB-based implementations of the SOMA data model that allows you to slice data directly from the cloud into Seurat or
                Bioconductor in R, and AnnData in Python.
              </Typography>
            </div>
            <InlineButton variant="light" icon={iconGithub} href="https://github.com/single-cell-data/TileDB-SOMA" target="_blank">
              TileDB SOMA
            </InlineButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SingleCellOpenSource;
