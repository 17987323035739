import React from 'react';
import { Link } from 'gatsby';
import './InlineButton.scss';
import classNames from 'classnames';
import Arrow from './assets/arrow.inline.svg';

type InlineButtonType = 'button' | 'submit' | 'reset';

interface InlineButtonProps {
  className?: string;
  href?: string;
  to?: string;
  target?: string;
  rel?: string;
  type?: InlineButtonType;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
  variant?: 'light' | 'black' | 'brand'; // defaults to blue
  icon?: any;
}

const InlineButton: React.FC<InlineButtonProps> = (props) => {
  const { className, href, to, target, rel, type, disabled, children, onClick, variant, icon: Icon } = props;
  const clnm = classNames('TDB-InlineButton', className, `TDB-InlineButton--${variant}`, { 'TDB-InlineButton--has-icon': !!Icon });

  if (to) {
    return (
      <Link className={clnm} to={to}>
        <div className="TDB-InlineButton__content">
          {Icon && <Icon className="TDB-InlineButton__Icon" />}
          {children}
        </div>
        <Arrow className="TDB-InlineButton__ArrowIcon" />
      </Link>
    );
  }
  if (href) {
    return (
      <a className={clnm} href={href} target={target} rel={target === '_blank' ? `noopener noreferrer` : rel}>
        <div className="TDB-InlineButton__content">
          {Icon && <Icon className="TDB-InlineButton__Icon" />}
          {children}
        </div>
        <Arrow className="TDB-InlineButton__ArrowIcon" />
      </a>
    );
  }
  return (
    <button className={clnm} type={type} disabled={disabled} onClick={onClick}>
      <div className="TDB-InlineButton__content">
        {Icon && <Icon className="TDB-InlineButton__Icon" />}
        {children}
      </div>
      <Arrow className="TDB-InlineButton__ArrowIcon" />
    </button>
  );
};

InlineButton.defaultProps = {
  type: 'button',
};

export default InlineButton;
