import React from 'react';
import Capabilities from '../../common/Capabilities';
import cloudPointIcon from './assets/point.png';
import dataSetsParallelIcon from './assets/datasets-parallel.png';
import singleCellIcon from './assets/slice_gene-cell.png';
import modalitiesIcon from './assets/combine_modalities.png';
import distributedAnalysisIcon from './assets/distributed_analysis.png';
import secureDistributionIcon from './assets/secure_distribution.png';

const capabilities = [
  {
    title: 'Model as analysis-ready arrays',
    description:
      'TileDB arrays are a natural fit for the highly sparse data generated by single-cell platforms. Manage RNA-seq data and count matrices in a cloud-native format, and store any number of samples in a compressed and lossless manner for tremendous storage savings.',
    icon: cloudPointIcon,
  },
  {
    title: 'Use established tooling',
    description:
      'Through our partnership with the Chan Zuckerberg Initiative on the Unified Single-Cell Data Model and API, TileDB implements a common, open data model. Use Seurat, Bioconductor and Scanpy, integrated directly with TileDB data.',
    icon: dataSetsParallelIcon,
  },
  {
    title: 'Slice directly by gene or cell',
    description:
      'Eliminate data wrangling and large downloads. Slice TileDB arrays directly using native query conditions on genes and cells in any of TileDB’s language APIs, including R, Python and C++.',
    icon: singleCellIcon,
  },
  {
    title: 'Combine modalities',
    description:
      'Preserve spatial context. In addition to sparse data, the TileDB array format excels at representing dense data types like biomedical imaging. As your research grows, combine datasets, code, ML models, files and more into TileDB Cloud groups to manage large projects.',
    icon: modalitiesIcon,
  },
  {
    title: 'Run distributed analyses',
    description:
      'Work across toolchains and languages to rapidly iterate using TileDB Cloud’s hosted Jupyter environment. For large computations, serverless user-defined functions and task graphs in TileDB Cloud let you parallelize custom algorithms efficiently and cost-effectively.',
    icon: distributedAnalysisIcon,
  },
  {
    title: 'Securely distribute your repositories',
    description:
      'Create your own repository of single-cell datasets using TileDB Cloud. Make cloud datasets discoverable with cataloging features that help attract new collaborators. Securely share data in private, or make it public in a predictable model that eliminates surprise infrastructure bills.',
    icon: secureDistributionIcon,
  },
];

const LidarCapabilities = () => {
  return <Capabilities variant="purple" capabilities={capabilities} title="Revolutionizing single-cell data analysis with TileDB Cloud" />;
};

export default LidarCapabilities;
