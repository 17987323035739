import React from 'react';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IndexLayout from '@layouts/index';
import Related from '@components/IndustryDataType/Related';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import SectionStayConnectedForBlog from '@page-components/Blog/SectionStayConnectedForBlog';
import Jumbotron from '../common/Jumbotron';
import SingleCellServiceCombination from './SingleCellServiceCombination';
import './SingleCellPage.scss';
import shareImage from './assets/single-cell_page_thumbnail.png';
import background from './assets/single-cell_hero.jpg';
import SingleCellCapabilities from './SingleCellCapabilities';
import SingleCellCloudAnalysis from './SingleCellCloudAnalysis';
import SingleCellQuote from './SingleCellQuote/SingleCellQuote';
import SingleCellOpenSource from './SingleCellOpenSource';

const SingleCellPage: React.FC = () => {
  return (
    <IndexLayout
      pageName="single-cell"
      helmet={{
        title: 'TileDB - Manage, analyze & collaborate on single-cell data at scale',
        description:
          'Use TileDB to analyze large single-cell datasets in the cloud, for secure collaboration with researchers and life sciences companies worldwide.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent" />
      <main className="main SingleCellPage">
        <Jumbotron
          title="A modern data platform for the next generation of single-cell biology"
          subtitle="Scale your work on TileDB Cloud and maintain compatibility with existing tools."
          backgroundImage={background}
          withGradient
          variant="purple"
        />
        <SingleCellQuote />
        <SingleCellCloudAnalysis />
        <SingleCellCapabilities />
        <SingleCellOpenSource />
        <SingleCellServiceCombination />
        <Related items={[industries.healthcare, dataTypes.populationGenomics]} />
        <Container>
          <SectionStayConnectedForBlog />
        </Container>
      </main>
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default SingleCellPage;
