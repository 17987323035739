import * as React from 'react';
import links from '@constants/links';
import ServiceCombination from '../../common/ServiceCombination';

const items = [
  {
    title: 'Arrays',
    subtitle: 'EMBEDDED',
    text: 'The open-source library that powers the open-source TileDB-SingleCell repository.',
    link: links.embedded,
  },
  {
    title: 'Database',
    subtitle: 'CLOUD',
    text: 'An advanced governance and computational platform for scaling your single-cell data and workloads.',
    link: links.cloud,
  },
];

const LidarServiceCombination: React.FC = () => <ServiceCombination variant="purple" title="Secret Sauce" items={items} />;

export default LidarServiceCombination;
