import './SingleCellCloudAnalysis.scss';
import * as React from 'react';
import ingestIcon from './assets/ingest.png';
import visualizeIcon from './assets/visualize.png';
import analyzeScaleIcon from './assets/analyze-scale.png';
import CloudAnalysis from '../../common/CloudAnalysis';

const items = [
  {
    title: 'Unify single-cell data',
    description:
      'Store data once as TileDB arrays, and enjoy interoperability with popular tools. We partnered with the Chan Zuckerberg Initiative to develop a data model that brings together the single-cell analysis toolchain.',
    icon: ingestIcon,
  },
  {
    title: 'Work at atlas scale',
    description:
      'Parallelize complex workloads and create distributed algorithms using TileDB Cloud. Access policies allow your work to stay within your organization, or securely share it with external collaborators to unlock atlas-scale analysis.',
    icon: visualizeIcon,
  },
  {
    title: 'Harness the cloud',
    description:
      'No more huge downloads and loading large datasets into memory. Cloud-native TileDB arrays allow you to slice straight from remote storage. Reduce costs and processing time by utilizing cost-efficient object storage services like S3.',
    icon: analyzeScaleIcon,
  },
];

const SingleCellCloudAnalysis: React.FC = () => (
  <CloudAnalysis variant="purple" className="CloudAnalysis--SingleCell" title="Supercharge your multi-omics analysis" items={items} />
);

export default SingleCellCloudAnalysis;
